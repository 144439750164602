.card-container {
  background: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.16) !important;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;
  li {
    list-style: unset;
    margin-left: 35px;
  }
  .chargement {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
    font-size: larger;
    font-style: italic;
  }
  .cardHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 14px;
      cursor: pointer;
    }
    .carheader-name {
      display: flex;
      flex-direction: column;
      .name {
        font-size: 0.9rem;
        font-weight: 700;
        color: #393a4f;
        line-height: 1.2;
        text-transform: capitalize;
        .important {
          background: #fb6047;
          position: absolute;
          top: 60px;
          right: 0;
          color: white;
          padding: 5px;
          border-radius: 10px 0px 0px 30px;
          box-shadow: 0px 10px 35px rgb(176 67 67 / 66%);
          font-weight: initial;
          span {
            margin-left: 10px;
            margin-right: 10px;
            i.far.fa-bell.fa-shake {
              margin-right: 5px;
            }
          }
        }
        .resolu {
          background: #5fc26d;
          position: absolute;
          top: 60px;
          right: 0;
          color: white;
          padding: 5px;
          border-radius: 10px 0px 0px 30px;
          box-shadow: 0px 10px 35px rgb(50 189 63 / 66%);
          font-weight: initial;
          span {
            margin-left: 20px;
            margin-right: 20px;
            i.fas.fa-check.fa-beat {
              margin-right: 7px;
            }
          }
        }
        .edited {
          background: rgb(247, 166, 0);
          position: absolute;
          top: 60px;
          right: 0;
          color: white;
          padding: 5px;
          border-radius: 10px 0px 0px 30px;
          box-shadow: 0px 10px 35px rgb(247 166 0 / 66%);
          font-weight: initial;
          text-transform: initial;
          span {
            margin-left: 20px;
            margin-right: 20px;
            i.fas.fa-exclamation.fa-beat {
              margin-right: 7px;
            }
          }
        }
        .tuto {
          background: #00a1e0;
          border-radius: 10px 0 0 30px;
          box-shadow: 0 10px 35px rgb(0 161 224);
          color: #fff;
          font-weight: 400;
          padding: 5px;
          position: absolute;
          right: 0;
          text-transform: none;
          top: 60px;
          span {
            margin-left: 20px;
            margin-right: 20px;
            i.fa-brain.fa-beat {
              margin-right: 7px;
            }
          }
        }
        .news {
          background: #008080;
          border-radius: 10px 0 0 30px;
          box-shadow: 0 10px 35px rgb(0 128 128);
          color: #fff;
          font-weight: 400;
          padding: 5px;
          position: absolute;
          right: 0;
          text-transform: none;
          top: 60px;
          span {
            margin-left: 20px;
            margin-right: 20px;
            i.fa-file-alt.fa-beat {
              margin-right: 7px;
            }
          }
        }
        .info {
          background: #323c3a;
          border-radius: 10px 0 0 30px;
          box-shadow: 0 10px 35px rgb(50 60 58);
          color: #fff;
          font-weight: 400;
          padding: 5px;
          position: absolute;
          right: 0;
          text-transform: none;
          top: 60px;
          span {
            margin-left: 20px;
            margin-right: 20px;
            i.fa-bullhorn.fa-beat {
              margin-right: 7px;
            }
          }
        }
      }
      .service {
        font-size: 0.8rem;
        color: #999;
        text-transform: capitalize;
      }
      .date {
        font-size: 0.8rem;
        color: #999;
        text-transform: capitalize;
        font-style: italic;
      }
    }
    .cardheader-edit {
      cursor: pointer;
    }
    .button-container-edit {
      display: flex;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 20px;
      gap: 20px;
    }
    .cardheader-container-delete {
      cursor: pointer;
    }
  }
  .postContent {
    .postContent-post {
      width: 100%;
      overflow-wrap: anywhere;
      margin-top: 10px;
      .todo-list {
        list-style: none;
      }
    }
    .postContent-image {
      margin-top: 1.5rem;
      .card-img {
        border-radius: 10px;
        max-width: 100%;
        min-width: 100%;
        max-height: 650px;
        object-fit: cover;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1) !important;
      }
      .imageArea {
        width: 100%;
        img {
          border-radius: 10px;
          max-width: 100%;
          min-width: 100%;
          max-height: 200px;
          object-fit: cover;
          box-shadow: 0px 0px 20px rgb(0 0 0 / 10%) !important;
        }
      }
      .rBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }
    .update-post {
      background: #f4f4f4;
      padding: 1rem;
      border-radius: 15px;
      position: relative;
      margin-bottom: 16px;
      textarea {
        width: 100%;
        border: 1px solid lightgrey;
        resize: none;
        border-radius: 5px;
      }
      .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.3rem;
        gap: 2rem;
        align-items: center;
        .sous-traitant-checkbox {
          display: flex;
          gap: 1rem;
        }
        .btn {
          border-radius: 5px;
          padding: 0.3rem;
          border: 1px solid lightgrey;
        }
        .btn-delete-pic {
          position: absolute;
          top: 42px;
          background: #fd2d02;
          padding: 5px;
          border-radius: 50%;
          color: white;
          width: 30px;
        }
      }
      img {
        border-radius: 10px;
        max-width: 100%;
        min-width: 100%;
        max-height: 400px;
        object-fit: cover;
        box-shadow: 0px 0px 20px rgb(0 0 0 / 10%) !important;
        opacity: 0.4;
      }
      .addImg {
        background: url(https://www.atelierdedo.com/static/img/placeholder.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 200px;
        img {
          border-radius: 10px;
          max-width: 100%;
          min-width: 100%;
          max-height: 200px;
          min-height: 200px;
          object-fit: cover;
          box-shadow: 0px 0px 20px rgb(0 0 0 / 10%) !important;
          opacity: 1;
        }
      }
      .input_btn {
        padding: 1rem;
        background: white;
        border-radius: 15px;
        margin: 1rem;
      }
    }
  }
  .carFooter {
    border-top: 1px solid #d3d3d3;
    display: flex;
    justify-content: space-around;
    background: #d3d3d317;
    border-radius: 15px;
    color: black;
    .cardfooter-like {
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      margin-top: 0.4rem;
      margin-bottom: 0.3rem;
      background: #ffffff;
      padding: 7px 15px;
      border-radius: 15px;
      color: black;
      font-weight: 400;
      box-shadow: 1px 1px 15px 1px rgba(212, 212, 212, 0.38);
      align-items: center;
      font-size: 14px;
      i.far.fa-thumbs-up {
        font-size: larger;
      }
    }
    .cardfooter-comment {
      align-items: center;
      cursor: pointer;
      display: flex;
      gap: 0.6rem;
      margin-top: 0.4rem;
      margin-bottom: 0.3rem;
      background: #ffffff;
      padding: 7px 15px;
      border-radius: 15px;
      color: black;
      font-weight: 400;
      box-shadow: 1px 1px 15px 1px rgba(212, 212, 212, 0.38);
      font-size: 14px;
    }
  }
}
