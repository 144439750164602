.comments-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .comment-container {
    display: flex;
    gap: 17px;
    background: #fbfbfb;
    border-radius: 14px;
    padding: 0.5rem;
    border: 1px solid lightgrey;
    .left-part {
      img {
        width: 50px;
        height: 50px;
        border-radius: 14px;
        object-fit: cover;
      }
    }
    .right-part {
      width: 100%;
      .comment-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        h3 {
          font-size: smaller;
          color: #b2b1b0;
          .author {
            color: blue !important;
            padding: 3px;
            background: #d6e7ed;
            border-radius: 8px;
            margin-left: 11px;
            font-weight: 100;
          }
        }
        span {
          font-size: smaller;
          color: #b2b1b0;
        }
      }
      p.comment-content {
        margin-top: 5px;
      }
    }
    .edit-comment {
      display: flex;
      justify-content: flex-end;
      .edit-comment-form {
        width: 100%;
        margin-top: 1rem;
        position: relative;
        .modif-com {
          width: 50%;
          margin-left: auto;
        }
        .mode-edition {
          margin-left: auto;
        }
      }
      .poub {
        position: absolute;
        right: 30px;
        color: red;
        top: 2px;
      }
    }
  }
  .comment-container.user {
    background: rgb(243 243 243);
    color: #000;
  }
  .commentForm {
    display: flex;
    gap: 1rem;
    background: #f3f3f3;
    border-radius: 15px;
    padding: 1rem;
    .commentForm-picture img {
      width: 50px;
      height: 50px;
      border-radius: 14px;
      object-fit: cover;
    }
    .commentForm-form {
      width: 100%;
      margin-top: 10px;
      .inputCommentaire {
        background: #4e5166;
        cursor: pointer;
        margin-left: auto;
        width: 50%;
        color: white;
        &:hover {
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.155) !important;
        }
      }
    }
  }
}
