.neige-event {
  position: absolute;
  top: 10px;
  width: 100%;
  .image-neige {
    width: 105%;
    position: absolute;
    top: -44px;
    z-index: 3;
    left: -23px;
  }
}

.neige-bloc-right {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  .image-neige {
    position: absolute;
    top: -33px;
    right: -16px;
  }
}

.neige-bloc-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .image-neige {
    position: absolute;
    top: -27px;
    left: -25px;
  }
}

.profil_picture {
  position: relative;
  .neige-event-profil {
    position: absolute;
    top: -24px;
    left: -11px;
  }
}

.lutin-container {
  bottom: -38px;
  position: fixed;
  z-index: 9;
  right: 0;
}

.lutin {
  position: relative;
  img {
    width: 284px;
  }
}

.bulle-texte {
  background-color: #94ad41f2;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 9px;
  left: -106px;
  padding: 10px;
  position: absolute;
  right: 52px;
  text-align: center;
  top: -190px;
  color: white;
}

.fermer-lutin {
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  left: -35px;
  position: absolute;
  top: 0;
  background: #94ad41f2;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.bandeauNoel {
  left: 27%;
  position: fixed;
  top: -3px;
  transition: opacity 0.5s ease-in-out;
  z-index: 39;
  width: 50%;
  img {
    width: 100%;
  }
}

.bandeauNoel.swing {
  transform-origin: top center;
  animation: swingAnimation 5s ease-in-out infinite;
  .close {
    position: absolute;
    background: white;
    color: black;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 85px;
    right: 61px;
    border: none;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 5.57);
  }
}

.overlayNoel {
  align-items: center;
  display: flex;
  z-index: -1;
  height: 172vh;
  width: 1006vw;
  position: absolute;
  left: -54%;
  top: -152px;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes swingAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.timerComponent {
  font-size: 50px;
  color: white;
  font-family: "Paris2024";
}
