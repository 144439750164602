.videoId {
  background: #f4f4f4;
  padding: 1rem;
  width: 70%;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  input[type="text"] {
    margin-left: 10px;
    margin-right: 10px;
    appearance: none;
    border: none;
    border-radius: 40px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    outline: none;
    padding: 9px;
    transition: all 0.3s ease;
  }
}
.parameters {
  gap: 2rem;
  background: #f4f4f4;
  padding: 1rem;
  width: 70%;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  .parameters_input {
    input[type="checkbox"] {
      margin-right: 10px;
    }
  }
}

.productTitle {
  background: #f4f4f4;
  border-radius: 10px;
  padding: 1rem;
  width: 70%;
}

.result_ai_product {
  background: #444654;
  border: 1px solid #d3d3d3;
  color: #fff;
  width: 100%;
  padding: 1rem;
  line-height: 2;
}

button.submit_prompt {
  background: #3d3a4e;
  background-size: 400%;
  border: none;
  border-radius: 1.5rem;
  color: #fff;
  cursor: pointer;
  height: 2.5rem;
  overflow: hidden;
  padding: 0 2rem;
  width: 50%;
  position: relative;
  margin: 10px auto 0px auto;
}

.texttoimg {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 70%;
  align-items: center;
}

.youtube_result {
  background: #f4f4f4;
  padding: 1rem;
  width: 70%;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  textarea {
    appearance: none;
    border: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    outline: none;
    padding: 9px;
    transition: all 0.3s ease;
    width: 500px !important;
  }
}
