.profil_formation_edit {
  visibility: hidden;
  position: fixed;
  top: 13%;
  left: 20%;
  padding: 10px 20px;
  width: 90%;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  background: white;
  visibility: hidden;
  opacity: 0;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.active_edit {
  visibility: visible;
  opacity: 1 !important;
  box-shadow: 0 0 100px #000 !important;
  padding: 2rem;
  z-index: 1;
  width: 70%;
}

.progress_formation {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.badge {
  background-color: rgba(255, 180, 0, 0.12);
  border: 0;
  border-radius: 16px;
  box-sizing: border-box;
  color: #ffb400;
  cursor: default;
  display: inline-flex;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  font-size: 0.8125rem;
  height: 24px;
  max-width: 100%;
  text-decoration: none;
  text-transform: capitalize;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0 10px 0 10px;
}

#referentSelect {
  width: 100%;
  margin-bottom: 2rem;
}
