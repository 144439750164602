.Navheader {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 14%);
  display: flex;
  height: 40px;
  justify-content: space-around;
  left: 0;
  padding: 0.5rem 0.75rem;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  z-index: 19;
  img {
    cursor: pointer;
  }
  .logo {
    img {
      cursor: pointer;
      max-height: 52px;
    }
  }
  .search {
    width: 33.33%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    .search-icon-container {
      position: absolute;
      right: 10px;
      top: 7px;
      color: #bfbdbd;
    }
    input.searchbar {
      appearance: none;
      border: none;
      border-radius: 40px;
      box-shadow: 0 0 8px rgb(0 0 0 / 25%);
      outline: none;
      padding: 9px;
      transition: all 0.3s ease;
      width: 70%;
    }
    .userlist {
      display: none;
    }
    .active {
      display: block;
    }
    ul.userlist {
      position: absolute;
      background: white;
      width: 70%;
      border: none;
      padding: 10px;
      transition: all 0.3s ease;
      border-radius: 10px;
      box-shadow: 0 6px 8px rgb(0 0 0 / 15%);
      margin-top: 37px;
    }
    .close {
      position: absolute;
      right: 15px;
    }
    li.liste {
      padding: 5px;
      cursor: pointer;
      &:hover {
        color: red;
        background: lightgrey;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
      }
      img {
        cursor: pointer;
        height: 32px;
      }
    }
  }
  .quicontacter_button {
    border: 1px solid transparent;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
    padding: 0.5rem;
    text-align: center;
    background: #f7a600;
  }
  .action {
    display: flex;
    position: relative;
    gap: 1.5rem;
    align-items: center;
    .profil {
      position: relative;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .profil_menu {
      position: absolute;
      top: 65px;
      right: 140px;
      padding: 10px 20px;
      width: 200px;
      box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      transition: 0.5s;
      background: white;
      visibility: hidden;
      box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.16) !important;
      opacity: 0;
      h3 {
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 10px 0;
        line-height: 1.2em;
        text-transform: capitalize;
        span {
          font-size: 14px;
          color: #cecece;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: -5px;
        right: 28px;
        width: 20px;
        height: 20px;
        background: white;
        transform: rotate(45deg);
      }
    }
    ul li {
      list-style: none;
      padding: 10px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      a {
        text-decoration: none;
        color: #555;
        transition: 0.5s;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          color: #fd2d01;
        }
      }
    }
    .active {
      visibility: visible !important;
      opacity: 1 !important;
    }
    span {
      color: #f7a600;
    }
  }
}

@media screen and (max-width: 960px) {
  .Navheader {
    width: 92%;
  }
  .action p {
    display: none;
  }
  .logo img {
    width: 125px;
  }
  .search {
    width: 100%;
  }
  .active_edit_notif {
    box-shadow: 0 0 100px #000 !important;
    opacity: 1 !important;
    padding: 2rem;
    visibility: visible;
    width: 290px;
    z-index: 1;
  }
  .profil_notif_edit {
    background: #fff;
    border-radius: 15px;
    position: absolute;
    right: 0px;
    top: 51px;
    transition: 0.5s;
  }
  .Navheader .action .profil_menu {
    right: -5px;
  }
  ul.userlist {
    width: 50%;
  }
  .display-button {
    width: 40%;
  }
  .post-message img {
    display: none;
  }
}
