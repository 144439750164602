.formation_container {
  background: white;
  padding: 0.6rem;
  margin: 1rem;
  border-radius: 15px;
  box-shadow: 0px 15px 32px rgb(0 0 0 / 16%) !important;
  position: relative;
  flex: 23%;
  &:hover {
    box-shadow: 0px 15px 32px rgb(0 0 0 / 20%) !important;
  }
  .formation_container_head {
    display: flex;
    justify-content: space-between;
    .formation_head_img {
      img {
        width: 35px;
        height: 35px;
        border-radius: 15px;
      }
    }
    .formation_head_label {
      p {
        background: #fffbec;
        color: #fed899;
        padding: 5px 10px 5px 10px;
        border-radius: 10px;
      }
    }
  }
  .formation_container_body {
    margin-top: 0.6rem;
    .formation_name {
      font-weight: 500;
      line-height: 1.2;
      margin-left: 1rem;
      font-size: 1.5rem;
    }
    .formation_lvl {
      display: flex;
      padding: 15px 15px 0 15px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      align-items: center;
      i.fas.fa-trophy {
        color: gold;
      }
      img {
        width: 30px;
      }
    }
  }
  .formation_container_footer {
    padding: 3px 15px 3px 15px;
    progress.progress.is-danger {
      width: 100%;
    }
    p {
      font-size: 0.8rem;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }
}

.searchUserCompetence {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    border: none;
    background-color: #4e5166;
    text-decoration: none;
    padding: 7px;
    font-size: 14px;
    color: #fff;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    cursor: pointer;
  }
  .searchbar_post {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    width: 210px;
    padding: 5px;
    font-size: 14px;
    color: black;
    border-radius: 0.5em;
    border: 2px solid #fff;
    margin-right: -10px;
  }
}

.formateur {
  display: flex;
  flex-direction: column;
  p {
    font-size: 12px;
    font-weight: 100;
    span {
      font-style: italic;
    }
  }
  .title {
    font-weight: 600;
  }
}

.formation_lvl {
  display: flex;
  gap: 1rem;
  align-items: center;
}
