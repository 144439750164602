.center-Block {
  background: transparent;
  margin-left: 20%;
  width: 100%;
  padding-right: 20px;
  .knowledge-container {
    background: white;
    margin-top: 1rem;
    border-radius: 15px;
    box-shadow: 0px 15px 32px rgb(0 0 0 / 16%) !important;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;
    height: 127px;
    justify-content: center;
    overflow: hidden;
    .brain {
      width: 250px;
      position: absolute;
      left: 40px;
    }
    .student {
      position: absolute;
      left: 0;
      top: 0;
      img {
        width: 331px;
      }
    }
    .search-container {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      .search-icon-container {
        position: absolute;
        z-index: 1;
        right: 25%;
        color: #bfbdbd;
        top: -8px !important;
      }
    }
    .search_knowledge {
      display: flex;
      justify-content: center;
      .searchbar_knowledge {
        width: 50%;
        border-radius: 40px;
        box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
        border: none;
        appearance: none;
        outline: none;
        transition: all 0.3s ease;
        padding: 9px;
        text-align: center;
      }
    }
    img {
      width: 100%;
    }
    input.searchbar_knowledge {
      position: absolute;
      width: 50%;
      left: 25%;
      border-radius: 40px;
      box-shadow: 0 0px 28px rgb(0 0 0 / 25%);
      border: none;
      appearance: none;
      outline: none;
      transition: all 0.3s ease;
      padding: 9px;
    }
  }
  .knowledger-card {
    background: transparent;
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;
    .knwoledge-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      border-bottom: 1px solid lightgrey;
      gap: 15px;
      .titre {
        font-size: 2rem;
        display: inline-block;
        color: darkslategrey;
        flex: 1;
      }
      .request_knowledge {
        background-color: #4e5166;
        border: none;
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.05rem;
        line-height: 1.5;
        padding: 0.5rem;
        text-align: center;
        width: 293px;
      }
      button.add-knowlegde {
        background-color: #4e5166;
        border: none;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0.05rem;
        line-height: 1.5;
        padding: 0.5rem;
        width: 135px;
      }
    }
    .filtre {
      display: flex;
      align-items: center;
      gap: 1rem;
      button {
        background-color: #ed7117 !important;
        color: #fff;
        border-radius: 15px;
        padding: 8px;
        font-size: 12px;
        text-align: center;
        border: none;
      }
    }
    .dialog {
      background: #fff;
      border-radius: 15px;
      box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem;
      position: relative;
      button.close-button {
        position: absolute;
        top: 5px;
        right: 38px;
        background: none;
        border: none;
        font-weight: 600;
        font-size: 20px;
      }
      form {
        display: flex;
        flex-direction: column;
        margin-top: 0.8rem;
        gap: 0.5rem;
        button.submit_request {
          border: 1px solid transparent;
          border-radius: 0.5rem;
          color: #fff;
          cursor: pointer;
          margin-top: 20px;
          padding: 0.5rem;
          text-align: center;
          background: #4e5166;
        }
      }
    }
  }
  .thread-container li {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-top: 15px;
    flex-wrap: wrap;
  }
}

.favSwitch {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
  .switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 27px;
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #d6d2d2;
      border-radius: 34px;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 19px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        border-radius: 50%;
        transition: 0.4s;
      }
    }
  }
  input {
    opacity: 0;
    width: 60px;
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  input:checked + .slider {
    background-color: green;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px green;
  }
  input:checked + .slider:before {
    transform: translateX(26px);
  }
}
