.requestformation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .requestformation_container_planif {
    gap: 0.5rem;
    align-items: center;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 15px;
    border: 1px solid lightgrey;
    cursor: zoom-in;
    justify-content: space-between;
    &:hover {
      box-shadow: 0px 1px 52px rgb(0 0 0 / 16%);
    }
    .resquestformation_body {
      span.formaName {
        font-weight: bold;
        font-size: 15px;
        color: #f7aa0e;
      }
      form.formStatus {
        display: flex;
        gap: 10px;
        flex-direction: inherit;
        margin: 0;
      }
    }
    .requestformation_date {
      span.formaName {
        font-weight: bold;
        font-size: 15px;
        color: #f7aa0e;
      }
    }
    p {
      font-weight: bold;
    }
  }
  .requestformation_container {
    gap: 0.5rem;
    align-items: center;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 15px;
    border: 1px solid lightgrey;
    justify-content: space-between;
    &:hover {
      box-shadow: 0px 1px 52px rgb(0 0 0 / 16%);
    }
    .resquestformation_body {
      overflow: hidden;
      span.formaName {
        font-weight: bold;
        font-size: 15px;
        color: #f7aa0e;
      }
      form.formStatus {
        display: flex;
        gap: 10px;
        flex-direction: inherit;
        margin: 0;
      }
    }
    .requestformation_date {
      span.formaName {
        font-weight: bold;
        font-size: 15px;
        color: #f7aa0e;
      }
    }
    p {
      font-weight: bold;
    }
  }
  .progress_formation {
    i.far.fa-trash-alt.poub {
      color: mediumvioletred;
      cursor: pointer;
    }
  }
  .sous_menu {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    margin-left: 10px;
    margin-bottom: 30px;
    p {
      background: #f7aa0e;
      padding: 6px;
      border-radius: 5px;
      box-shadow: 0px 1px 52px rgb(0 0 0 / 16%) !important;
      width: 90px;
      text-align: center;
      cursor: pointer;
      border: 1px solid lightgrey;
      color: white;
    }
  }
  .accordion-item {
    .accordion-content {
      background: white;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      padding: 1rem;
      margin: 0.5rem;
      border-radius: 15px;
      border: 1px solid lightgrey;
      p {
        font-weight: bold;
        color: white;
        font-size: larger;
        text-align: center;
        background: #f7aa0e;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 15px 32px rgb(0 0 0 / 16%) !important;
      }
      label {
        font-weight: 600;
        margin-bottom: 9px;
        font-size: 14px;
      }
      form {
        input.calendar {
          background: rgb(35 107 162);
          color: white;
        }
      }
    }
  }
}

.calendar-formateur {
  position: relative;
  padding: 2rem;
  height: 520px;
  .calendar-event {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 1rem;
    border: 1px solid lightgrey;
    box-shadow: 0px 15px 32px rgb(0 0 0 / 86%) !important;
    z-index: 4;
    p {
      padding: 15px 0px 15px 0px;
    }
    button {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}

.progressbar-wrapper {
  background: #f4f4f4;
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 1rem;
  width: 95%;
  margin: auto;
}

.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
  p {
    margin-top: 10px;
  }
}

.progressbar li:before {
  width: 50px;
  height: 50px;
  content: "";
  line-height: 55px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 3px auto;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 30px;
  left: -50%;
  z-index: 0;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: black;
  font-weight: bold;
}
.progressbar li.active:before {
  border-color: #f7aa0e;
  background: #f7aa0e;
}
.progressbar li.active + li:after {
  background-color: #f7aa0e;
}
.progressbar li.active:before {
  background: #f7aa0e;
  background-size: 60%;
}
.progressbar li.active-red {
  color: black;
  font-weight: bold;
}
.progressbar li.active-red:before {
  background: #362d2d;
  background-size: 60%;
  color: white;
}

.progressbar li::before {
  background: #fff;
  background-size: 60%;
}
.progressbar {
  counter-reset: step;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
}

.nullformation {
  display: flex;
  justify-content: center;
  font-size: large;
  margin-top: 1rem;
}
