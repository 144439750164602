@font-face {
  font-family: "Paris2024";
  src: local("Paris2024"),
    url(../../asset/Paris2024Semibold.ttf) format("truetype");
}

.countDownJo {
  background: #111;
  border-radius: 5px;
  box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
  color: #56525d;
  margin-top: 1rem;
  padding: 1rem;
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  position: relative;
}

.countdown-container {
  background: #000;
  border-radius: 5px;
  box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
  color: #56525d;
  margin-top: 1rem;
  padding: 1rem;
  align-items: center;
  display: flex;
  position: relative;
  gap: 3rem;
  padding-left: 7rem;
  height: 250px;
  ove .image {
    height: 175px;
    img {
      width: 220px;
      position: absolute;
      bottom: 0;
      left: 80px;
    }
  }
  .timer-container {
    p {
      font-size: 9em;
      font-family: Paris2024;
      .days {
        color: #ed008c;
      }
      .exclamation {
        color: #f9a61a;
      }
    }
  }
}

.years-start {
  color: #e4027f;
}

.image-start {
  bottom: 0 !important;
  left: 40px !important;
  position: absolute;
  width: 180px !important;
}
.timer-container-start {
  display: flex;
  flex-direction: column;
  font-size: 5px;
  color: white;
  p {
    border-bottom: 3px solid orange;
  }
  button {
    padding: 10px;
    border: none;
    border-radius: 10px;
    background: #e4027f;
    color: white;
    font-size: 19px;
    font-weight: 700;
    font-family: "Paris2024";
    margin-top: 13px;
    cursor: pointer;
  }
  button:hover {
    background: #e4027fa6;
  }
}

.confeti {
  left: 98px;
  position: absolute;
  top: -45px;
  width: 220px;
}
