.information_bloc {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
  .bloc {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 500px;
    .tire_bloc {
      padding: 1rem;
      font-weight: 700;
      width: 240px;
      border-top: 1px solid lightgrey;
    }
  }
  .computer {
    bottom: 20px;
    position: absolute;
    left: 10px;
    img {
      width: 450px;
    }
  }
  .rightBloc {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 65%;
    .interogation {
      position: absolute;
      top: -50px;
    }
    .content_quicontacter {
      background: #f4f4f4;
      border-radius: 10px;
      display: flex;
      gap: 1rem;
      padding: 2rem;
      width: 70%;
      flex-direction: column;
      label {
        font-size: 18px;
        font-weight: 700;
      }
      select {
        border: 1px solid #ced4da;
        border-radius: 5px;
        color: #555770;
        display: block;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 1rem 0.75rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
    .solution {
      align-items: center;
      background: #f4f4f4;
      border-radius: 10px;
      display: flex;
      margin: 1rem auto;
      padding: 2rem;
      width: 70%;
      .solutionContent {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        span {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 1rem;
          text-align: center;
          border: 1px solid lightgray;
          border-radius: 10px;
          padding: 0.5rem;
          background: white;
        }
        p ul {
          padding: 10px !important;
          li {
            list-style: disc !important;
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.question {
  font-size: 18px;
  font-weight: 600;
  padding: 1rem;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
