.crop_img {
  visibility: hidden;
  position: fixed;
  padding: 10px 20px;
  width: 552px;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  background: white;
  visibility: hidden;
  opacity: 0;
  top: 15%;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
  }
}

.active_crop_img {
  box-shadow: 0 0 100px #000 !important;
  margin: auto;
  opacity: 1 !important;
  padding: 0 !important;
  position: absolute;
  right: 9%;
  top: 30px;
  visibility: visible;
  width: 1400px;
  z-index: 999;

  h3 {
    border-radius: 15px 15px 0 0;
    color: black;
    margin: 0;
    padding: 1rem;
    border-bottom: 1px solid lightgray;
    margin-left: 5px;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: -1;
    border-radius: 15px;
  }
  &::after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: -2;
  }
}

.active_contact {
  box-shadow: 0 0 100px #000 !important;
  left: 10%;
  right: 10%;
  margin: auto;
  min-width: 1100px;
  opacity: 1 !important;
  padding: 0 !important;
  position: absolute;
  top: 30px;
  visibility: visible;
  z-index: 999;
  h3 {
    border-radius: 15px 15px 0 0;
    color: black;
    margin: 0;
    padding: 1rem;
    border-bottom: 1px solid lightgray;
    margin-left: 5px;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: -1;
    border-radius: 15px;
  }
  &::after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: -2;
  }
}

.active_help {
  box-shadow: 0 0 100px #000 !important;
  margin: auto;
  min-width: 900px;
  opacity: 1 !important;
  padding: 0 !important;
  position: absolute;
  right: 90%;
  visibility: visible;
  z-index: 999;
  top: -70%;
  h3 {
    border-radius: 15px 15px 0 0;
    color: black;
    margin: 0;
    padding: 1rem;
    border-bottom: 1px solid lightgray;
    margin-left: 5px;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: -1;
    border-radius: 15px;
  }
  &::after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: -2;
  }
}

.content_help {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
  .leftHelp {
    position: relative;
    .interogation {
      bottom: 20px;
      left: 10px;
      position: absolute;
      z-index: -1;
      opacity: 0.5;
    }
    .deskImg {
      img {
        width: 450px;
      }
    }
  }
  .rightHelp {
    width: 100%;
    .items {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 90%;
      .random {
        align-items: center;
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        padding: 2rem;
        background-color: rgba(58, 53, 65, 0.04);
        width: 100%;
        border-radius: 5px;
        margin-bottom: 10px;
      }
      h3 {
        border-bottom: 1px solid #d3d3d3;
        padding: 1rem;
        width: 95%;
        text-align: center;
        margin: 0px 0px 0.75rem;
        font-weight: 600;
        letter-spacing: 0px;
        font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont,
          "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 1.25rem;
        line-height: 1.334;
        color: rgba(58, 53, 65, 0.87);
      }
      p {
        line-height: 1.5;
        letter-spacing: 0.15px;
        font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont,
          "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 400;
        font-size: 1.2rem;
        color: rgba(58, 53, 65, 0.6);
        text-align: left !important;
        align-self: flex-start;
        margin-left: 2rem;
      }
      .searchUserCompetence {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 90%;
        padding: 1rem;
        color: rgba(58, 53, 65, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 6px;
        margin: 13px 32px 32px 32px;
        position: relative;
        overflow-y: auto;
        vertical-align: middle;
        text-align: left;
        max-width: 900px;
        box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
        .searchbar_post {
          border: 2px solid #fff;
          border-radius: 0.5em;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
          color: #000;
          font-size: 14px;
          padding: 5px;
          width: 90%;
        }
      }
      .useronline-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 90%;
        margin-bottom: 2rem;
        .user-online {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.2rem;
          .left-side img {
            width: 50px;
            border-radius: 50%;
          }
          .right-side {
            display: flex;
            flex-direction: column;
            span {
              color: rgba(58, 53, 65, 0.87);
              line-height: 1.5;
              letter-spacing: 0.15px;
              font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
                "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
              font-weight: 400;
              font-size: 0.875rem;
              display: block;
            }
            .service {
              display: flex;
              gap: 1rem;
              justify-content: space-between;
              min-width: 220px;
            }
          }
        }
        .user-online:hover {
          background-color: rgba(58, 53, 65, 0.04);
        }
      }
    }
  }
}

.active_overlay {
  box-shadow: 0 0 100px #000 !important;
  left: 5%;
  opacity: 1 !important;
  padding: 0 !important;
  position: absolute;
  top: 100px;
  visibility: visible;
  z-index: 9;
  margin: auto;
  width: 90%;
  h3 {
    border-radius: 15px 15px 0 0;
    color: black;
    margin: 0;
    padding: 1rem;
    border-bottom: 1px solid lightgray;
    margin-left: 5px;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: -1;
    border-radius: 15px;
  }
  &::after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: -2;
  }
}

.import_img {
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.content_crop {
  .crop_container {
    display: flex;
    gap: 0.5rem;
    justify-content: space-evenly;
    margin-top: 20px;
    .img_select {
      width: 45%;
    }
    .img_button {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      button {
        background: #4e5166;
        border: 1px solid transparent;
        border-radius: 0.5rem;
        color: #fff;
        cursor: pointer;
        padding: 0.5rem;
        text-align: center;
      }
    }
    .box {
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img_result img {
        width: 300px;
        object-fit: contain;
        height: auto;
      }
    }
  }
}
