.post-container-event-quizz {
  align-items: center;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
  color: #fff6eb;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
  padding: 0.7rem;
  position: relative;
  .quizz {
    position: absolute;
    right: 36%;
    top: -6px;
    width: 290px;
  }
  .quizzFire {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
  }
  .quizzImgNew {
    bottom: -25px;
    left: 3rem;
    position: absolute;
    img {
      width: 275px;
    }
  }
}

.quizzContent {
  align-items: center;
  color: #f7aa0c;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 331px;
  .quizzTexte {
    text-transform: uppercase;
    font-weight: 700;
  }
  .quizzChrono {
    text-transform: uppercase;
    font-weight: 700;
    color: #f6c25c;
    font-style: italic;
  }
  .url_site {
    background: #4e5166;
    background-size: 400%;
    border: none;
    border-radius: 1.5rem;
    color: #fff;
    height: 2.5rem;
    overflow: hidden;
    padding: 0 2rem;
    position: relative;
    margin-top: 1rem;
    cursor: pointer;
    width: 263px;
  }
  .url_site:hover::before {
    transform: scaleX(1);
  }
  .button-content {
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  .url_site::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(
      82.3deg,
      rgba(150, 93, 233, 1) 10.8%,
      rgba(99, 88, 238, 1) 94.3%
    );
    transition: all 0.475s;
  }
}
