.mainContainer {
  display: flex;
  padding: 60px 0px 0px 0px;
  transition: all 0.3s;
  .leftBlock {
    width: 20%;
    position: fixed;
    z-index: 1;
    .left_Profil {
      align-items: center;
      background: #fff;
      box-shadow: 0 15px 32px rgba(0, 0, 0, 0.163) !important;
      display: flex;
      gap: 1.5rem;
      justify-content: flex-start;
      margin-right: 1rem;
      padding: 1.6rem;
      border-radius: 5px 5px 0px 0px;
      margin-top: -5px;
      img {
        width: 70px;
        height: 70px;
        border-radius: 14px;
        object-fit: cover;
      }
      span {
        font-size: 1.3rem;
        font-weight: 600;
        color: #393a4f;
        line-height: 1.2;
        text-transform: capitalize;
      }
      p {
        font-size: 0.9rem;
        color: #999;
        text-transform: capitalize;
      }
    }
    .left_menu {
      background: #fff;
      border-radius: 5px 0px 5px 0px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.16) !important;
      margin-right: 1rem;
      padding: 1.6rem;
      min-height: 100vh;
      position: relative;
      .admin {
        .admin__button {
          background: rgb(247 166 0 / 35%);
          border: 1px solid transparent;
          border-radius: 0.5rem;
          color: #b67c05;
          cursor: pointer;
          margin-top: 20px;
          padding: 0.5rem;
          text-align: center;
        }
        .coach__button {
          background: rgb(78 81 102);
          border: 1px solid transparent;
          border-radius: 0.5rem;
          color: #ffffff;
          cursor: pointer;
          margin-top: 20px;
          padding: 0.5rem;
          text-align: center;
        }
      }
      ul.li {
        display: flex;
        gap: 16px;
        font-weight: 500;
        color: #393a4f;
        padding: 10px;
        border-bottom: 1px solid #eae9e9;
        cursor: pointer;
        &:hover {
          color: lightgrey;
          transition: all 0.3s;
        }
        span.numberRow {
          align-items: center;
          background: red;
          border-radius: 50%;
          color: #fff;
          display: flex;
          font-size: 10px;
          height: 15px;
          justify-content: center;
          padding: 2px;
          width: 15px;
        }
      }
      .trendingPro {
        background: #fff;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        left: 0;
        padding-top: 1rem;
        position: absolute;
        top: -133px;
        width: 100%;
        max-height: 90vh;
        overflow-y: scroll;
        .head-trending {
          display: flex;
          justify-content: space-around;
          button {
            border: 1px solid #f6f6f6;
            border-radius: 50%;
            padding: revert;
            background: #f6f6f6;
          }
        }
        .items {
          width: 100%;
          .useronline-container {
            width: 100%;
            .user-online {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              display: flex;
              gap: 0.5rem;
              align-items: center;
              padding: 0.4rem;
              margin: 0.5rem;
              border-radius: 5px;
              border: 1px solid lightgrey;
              &:hover {
                background: #fff2f2;
                box-shadow: 0px 0px 25px rgb(0 0 0 / 16%) !important;
                cursor: pointer;
              }
              .left-side {
                img {
                  width: 50px;
                  height: 50px;
                  object-fit: cover;
                  border-radius: 14px;
                  cursor: pointer;
                }
              }
              .right-side {
                display: flex;
                flex-direction: column;
                span {
                  font-size: 0.9rem;
                  font-weight: 700;
                  color: #393a4f;
                  line-height: 1.2;
                  text-transform: capitalize;
                }
                .service {
                  font-size: 0.7rem;
                  color: #999;
                  text-transform: inherit;
                }
              }
            }
          }
        }
        .blackButton {
          width: 80%;
          margin: auto;
        }
      }
      .trendingPro::-webkit-scrollbar {
        display: none;
      }
      .equipe_prod {
        background: #fce0a6;
        border: 1px solid transparent;
        border-radius: 15px 15px 0 0;
        bottom: 230px;
        color: #bf8718;
        cursor: pointer;
        font-size: 15px;
        left: 32px;
        padding: 0.5rem;
        position: absolute;
        right: 32px;
        text-align: center;
        z-index: 2;
        .userProdimg {
          bottom: -12px;
          left: -18px;
          position: absolute;
          img {
            width: 55px;
          }
        }
      }
    }
  }
  .centerBlock {
    background: transparent;
    margin-left: 20%;
    width: 100%;
    .post-container {
      background: white;
      margin-top: 1rem;
      padding: 1rem;
      border-radius: 5px;
      box-shadow: 0px 15px 32px rgb(0 0 0 / 16%) !important;
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 1rem;
      .search_post {
        position: relative;
        .search-icon-container {
          position: absolute;
          right: 23px;
          top: 7px;
          color: #bfbdbd;
        }
      }
      .chargement {
        display: flex;
        gap: 1rem;
        justify-content: center;
        padding: 1.9rem;
      }
      .post-container-inside {
        .post-container-post {
          display: flex;
          gap: 1rem;

          .left-post-bloc {
            .user-profil {
              img {
                width: 50px;
                height: 50px;
                border-radius: 5px;
                object-fit: cover;
              }
            }
          }
          .right-bloc {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            .form-input {
              display: flex;
              align-items: center;
              padding: 0 0 20px 0;
              border-bottom: 1px solid lightgrey;
              .post-form {
                width: 100%;
                display: flex;
                #message {
                  width: 98%;
                  resize: none;
                  border: none;
                  border-radius: 5px;
                }
                ::placeholder {
                  font-size: 1rem;
                  padding: 0.8rem;
                }
                .todo-list {
                  margin-left: 35px;
                  li {
                    list-style: none !important;
                  }
                }
                li {
                  list-style: inherit !important;
                  margin-left: 1.5rem;
                }
              }
            }
            .display-button {
              width: 30%;
              display: flex;
              justify-content: flex-end;
              position: relative;
              button.post-message {
                background: #4e5166;
                padding: 0.6rem;
                border-radius: 10px;
                color: white;
                border: 1px solid lightgrey;
                font-size: 0.9rem;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 5px;
                img {
                  width: 23px;
                }
              }
              button.cancel {
                background: #fff2f2;
                padding: 0.3rem;
                border-radius: 5px;
                color: black;
                border: 1px solid lightgrey;
                position: absolute;
                bottom: -100px;
                right: 15px;
                z-index: 1;
                width: 90px;
              }
            }
            .image-uplod {
              .message-icon {
                position: relative;
                margin-top: 5px;
                margin-left: 2px;
                display: flex;
                gap: 10px;
                align-items: center;
                span {
                  font-size: 0.9rem;
                  color: #999;
                  font-style: italic;
                }
                input#file-upload {
                  position: absolute;
                  left: 0;
                  width: 160px;
                  opacity: 0;
                }
              }
            }
            .image-uplod {
              display: flex;
              justify-content: space-between;
              align-items: center;
              select#color-select {
                margin-top: 10px;
              }
              input[type="datetime-local"] {
                border: 1px solid #ced4da;
                border-radius: 5px;
                color: #555770;
                display: block;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                padding: 0.2rem 0.75rem;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
                margin-top: 10px;
              }
              .sous-traitant-checkbox {
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
                margin-top: 10px;
              }
            }
          }
        }
        .preview-container {
          background: #f4f4f4;
          padding: 1rem;
          border-radius: 5px;
          margin-top: 10px;
          display: flex;
          gap: 1rem;
          .preview-header {
            .name {
              font-size: 0.9rem;
              font-weight: 900;
              color: #393a4f;
              line-height: 1.2;
              text-transform: capitalize;
            }
            .service {
              font-size: 0.8rem;
              color: #999;
              text-transform: capitalize;
            }
            .date {
              font-size: 0.8rem;
              color: #999;
              text-transform: capitalize;
            }
          }
          .preview-left {
            img {
              width: 50px;
              border-radius: 14px;
            }
          }
          .preview-content {
            width: 100%;
            overflow-wrap: anywhere;
            margin-top: 10px;
            img {
              border-radius: 10px;
              max-width: 100%;
              min-width: 100%;
              max-height: 400px;
              object-fit: cover;
            }
            li {
              list-style: inherit !important;
              margin-left: 1.5rem;
            }
          }
        }
        .ping {
          animation: ping 4s cubic-bezier(0.39, 0.575, 0.565, 1) 3s infinite
            both;
        }
      }
      .search_post {
        .searchbar_post {
          width: 97%;
          border-radius: 20px;
          box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
          border: none;
          appearance: none;
          outline: none;
          transition: all 0.3s ease;
          padding: 9px;
        }
      }
    }
    .scheduled-post {
      background: rgba(253, 92, 99, 0.561);
      border-radius: 0 0 15px 15px;
      box-shadow: 0 15px 32px rgba(0, 0, 0, 0.163) !important;
      color: #fff;
      display: flex;
      gap: 0.5rem;
      justify-content: flex-start;
      margin-top: -12px;
      padding: 1.6rem 0 1rem 1rem;
      flex-direction: column;
    }
  }
  .rightBlock {
    width: 23%;
    .right-block-container {
      margin-top: 2rem;
      background: white;
      padding: 1.6rem;
      margin: 1rem;
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.5rem;
      box-shadow: 0px 15px 32px rgb(0 0 0 / 16%) !important;
      flex-direction: column;
      position: relative;
    }
  }
}

.help_stars {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  p {
    width: 70%;
    text-align: right;
    color: rgba(58, 53, 65, 0.87);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
  }
  button {
    background: #4e5166;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    padding: 0.5rem;
    text-align: center;
    width: 90%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.56) !important;
  }
}
.helpImg {
  bottom: -8px;
  left: -11px;
  position: absolute;
  img {
    width: 123px;
  }
}

.accordion {
  border-radius: 5px;
  border: none !important;
  .accordion-item {
    margin-bottom: 1rem;
    .title {
      background-color: #f6f6f6;
      color: #393a4f;
      cursor: pointer;
      padding: 15px;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
      font-weight: 600;
    }
  }
}

.accordion .accordion-item.active .panel {
  max-height: 100%;
  transition: max-height 0.25s ease-in;
}

//CKEDITOR
.ck.ck-editor__editable_inline[dir="ltr"] {
  text-align: left;
  width: 100%;
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  background: white;
  border-radius: 13px;
  width: 97%;
}

.thread-container li {
  list-style-type: none;
}

//Admin
.centerBlock_admin {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
  margin: 1rem;
  padding: 1.6rem;
  position: relative;
  width: 100%;
  margin-left: 20%;
  .useronline-container-admin {
    width: 100%;
    .user-online {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      padding: 0.4rem;
      margin: 0.5rem;
      border-radius: 15px;
      border: 1px solid lightgrey;
      &:hover {
        background: #fff2f2;
        box-shadow: 0px 0px 25px rgb(0 0 0 / 16%) !important;
        cursor: pointer;
      }
      .left-side {
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 14px;
          cursor: pointer;
        }
      }
      .right-side {
        display: flex;
        flex-direction: column;
        span {
          font-size: 0.9rem;
          font-weight: 900;
          color: #393a4f;
          line-height: 1.2;
          text-transform: capitalize;
        }
        .service {
          font-size: 0.7rem;
          color: #999;
          text-transform: capitalize;
        }
      }
    }
    .see_more {
      display: flex;
      justify-content: center;
      padding: 1rem;
      button {
        background: #4e5166;
        border: 1px solid transparent;
        border-radius: 0.5rem;
        color: #fff;
        cursor: pointer;
        padding: 0.5rem;
        width: 100%;
      }
    }
    img {
      width: 35px;
      border-radius: 50%;
    }
  }
  button.create_user {
    position: absolute;
    right: 20px;
    background: white;
    padding: 0.5rem;
    border: 1px solid grey;
    border-radius: 5px;
    cursor: pointer;
  }
  .see_more {
    button {
      background: #4e5166;
      border: 1px solid transparent;
      border-radius: 0.5rem;
      color: #fff;
      cursor: pointer;
      padding: 0.5rem;
      width: 100%;
    }
  }
}

//admin

.centerBlock_admin {
  .search_admin {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .searchAdmin {
      position: relative;
      width: 50%;
      input.searchbar_admin {
        width: 95%;
        border-radius: 40px;
        box-shadow: 0 0px 8px rgb(0 0 0 / 25%);
        border: none;
        appearance: none;
        outline: none;
        transition: all 0.3s ease;
        padding: 9px;
        margin-bottom: 20px;
        margin-left: 10px;
      }
      .search-icon-container {
        color: #bfbdbd;
        position: absolute;
        right: 23px;
        top: 7px;
      }
    }
  }
}

.usersOnlineDashboard {
  border-radius: 15px;
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem;
  padding: 0.4rem;
  flex-direction: column;
  span {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #f8ad13;
    background: rgba(255, 180, 0, 0.12);
    border-radius: 5px;
    padding: 1rem;
  }
  li.userOnline_list {
    align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    display: flex;
    gap: 0.5rem;
    margin: 0.5rem;
    padding: 0.4rem;
    i.fas.fa-circle {
      color: #ff7000;
    }
    img {
      width: 30px;
      border-radius: 50%;
    }
  }
}

@keyframes ping {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1165px) {
  .left_Profil {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 962px) {
  .mainContainer {
    .leftBlock {
      display: none;
    }
    .centerBlock {
      background: transparent;
      width: 100%;
    }
    .rightBlock {
      display: none;
    }
  }
}

.random {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  span {
    cursor: pointer;
  }
}

//EVENT
.post-container-event {
  align-items: flex-start;
  background: white;
  border-radius: 5px;
  box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
  color: #56525d;
  display: flex;
  margin-top: 1rem;
  padding: 1rem;
  position: relative;
  background-image: url(https://skills-manager-preprod.herokuapp.com/static/media/bg.02625f128b2b8ac0d501.png);
  background-size: cover;
  background-position: right 0px bottom -130px;
  .eventGlobal {
    display: flex;
    justify-content: center;
    width: 100%;

    .userInfoBirth {
      display: flex;
      gap: 1rem;
      align-items: center;
      .quizzFire {
        position: absolute;
        top: 0;
        width: 210px;
        left: 170px;
        max-height: 240px;
        overflow: hidden;
      }
      .ballon_left {
        bottom: -7px;
        left: 10px;
        position: absolute;
        width: 170px;
      }
      .ballon_right {
        bottom: -7px;
        position: absolute;
        right: 8px;
        width: 170px;
        transform: scaleX(-1);
      }
      .eventBirth img {
        width: 90px;
      }
      .birthDateEvent {
        animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        p {
          align-items: center;
          display: flex;
          font-family: Vujahday Script;
          font-size: 2rem;
          font-weight: 700;
          gap: 1rem;
          list-style-type: none;
          text-align: center;
          text-shadow: 1px 1.2px #fce0a6;
          color: #ba820f;
          margin-left: 0.5rem;
        }
        h2 {
          -webkit-animation: puff-in-center 0.7s
            cubic-bezier(0.47, 0, 0.745, 0.715) both;
          animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
            both;
          text-align: center;
          text-shadow: 1px 1.2px #000;
          span {
            color: #f7a600;
            font-family: Vujahday Script;
            font-size: 2.2rem;
          }
        }
      }
    }
  }
}

.scroll-to-top-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scroll-progress {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #f6a705;
  z-index: -1;
}

.scroll-to-top-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #000;
  cursor: pointer;
}

.scroll-progress::before {
  content: attr(data-scroll) "%";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #f6a705;
}

.quiContacter {
  .thinkContact {
    align-items: center;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px !important;
    cursor: pointer;
    display: flex;
    gap: 1.5rem;
    margin: 2.5rem 1rem 1rem;
    min-height: 136px;
    padding: 1rem;
    position: relative;
    .contactImg {
      bottom: -5px;
      position: absolute;
      right: -3px;
      img {
        width: 170px;
      }
    }
    .thinkContant {
      display: flex;
      flex-direction: column;
      align-content: space-between;
      p {
        color: rgba(58, 53, 65, 0.87);
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5;
        width: 90%;
      }
      button {
        background: #4e5166;
        border: 1px solid transparent;
        border-radius: 0.5rem;
        color: #fff;
        cursor: pointer;
        margin-top: 20px;
        padding: 0.5rem;
        text-align: center;
        width: 95%;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.56) !important;
      }
    }
  }
}

@font-face {
  font-family: "Starjedi";
  src: local("Starjedi"), url(../../asset/Strjmono.ttf) format("truetype");
}

.tabPost {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  position: relative;
  gap: 1rem;
  .allPost {
    text-align: center;
    width: 50%;
    background: #ffffff;
    padding: 1em;
    border-radius: 10px;
    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
      -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: transform 0.5s;
    color: #4e5166;
    font-weight: 700;
    &:hover {
      box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
        inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
        -0.5px -0.5px 0px rgba(255, 255, 255, 1),
        0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
        0px 12px 10px -10px rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.1);
      transform: translateY(0.5em);
    }
  }
  .techPost {
    text-align: center;
    width: 50%;
    background: #ffffff;
    padding: 1em;
    border-radius: 10px;
    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
      -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: transform 0.5s;
    color: #4e5166;
    font-weight: 700;
    &:hover {
      box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
        inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
        -0.5px -0.5px 0px rgba(255, 255, 255, 1),
        0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
        0px 12px 10px -10px rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.1);
      transform: translateY(0.5em);
    }
  }
  .active {
    background: #e5e5e5;
    color: #201f1f;
    font-weight: 700;
    cursor: pointer;
    transition: transform 0.5s;
    box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
      inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
      -0.5px -0.5px 0px rgba(255, 255, 255, 1),
      0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
      0px 12px 10px -10px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.orga {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
//Artifices

$particles: 60;
$width: 500;
$height: 400;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
    random($width)-$width /
      2 +
      px
      random($height)-$height /
      1.2 +
      px
      hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff;
}
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation-delay($settings) {
  -moz-animation-delay: $settings;
  -webkit-animation-delay: $settings;
  -o-animation-delay: $settings;
  -ms-animation-delay: $settings;
  animation-delay: $settings;
}

@mixin animation-duration($settings) {
  -moz-animation-duration: $settings;
  -webkit-animation-duration: $settings;
  -o-animation-duration: $settings;
  -ms-animation-duration: $settings;
  animation-duration: $settings;
}

@mixin animation($settings) {
  -moz-animation: $settings;
  -webkit-animation: $settings;
  -o-animation: $settings;
  -ms-animation: $settings;
  animation: $settings;
}

@mixin transform($settings) {
  transform: $settings;
  -moz-transform: $settings;
  -webkit-transform: $settings;
  -o-transform: $settings;
  -ms-transform: $settings;
}

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation(
    (
      1s bang ease-out 3s backwards,
      1s gravity ease-in 3s backwards,
      5s position linear 3s backwards
    )
  );
}

.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 8s));
}

@include keyframes(bang) {
  to {
    box-shadow: $box-shadow;
  }
}

@include keyframes(gravity) {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes puff-in-center {
  0% {
    transform: scale(2);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}
