.left_menu_weather {
  border-radius: 15px;
  .container_weather {
    border-radius: 15px;
    .rainy {
      background: white;
      margin: 15px;
      border-radius: 15px;
      box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.1);
      margin: 15px;
      background: url(https://img.freepik.com/free-vector/dark-gray-clouds-background-papercut-style_1017-26303.jpg?size=626&ext=jpg);
      background-position-y: -127px;
      .body_container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        .tool_info {
          display: flex;
          flex-direction: column;
          color: white;
          padding: 1rem;
          z-index: 2;
          .outil_name {
            font-weight: 700;
            margin-bottom: 5px;
            font-size: large;
          }
          p {
            font-size: 14px;
          }
        }
        .tool_weather {
          padding: 1rem;
          z-index: 2;
          position: relative;
          img {
            width: 70px;
            z-index: 5;
            position: inherit;
          }
          .rain {
            animation: raindrop 2.5s linear infinite;
            background-color: #9393fd;
            right: 24px;
            position: absolute;
            width: 5px;
            height: 10px;
            top: 50px;
            border-radius: 5px;
          }
          .rain2 {
            animation: raindrop 2s linear infinite;
            background-color: #9393fd;
            right: 66px;
            position: absolute;
            width: 5px;
            height: 10px;
            top: 54px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.lightning {
  background: url(https://img.freepik.com/free-vector/dark-clouds-with-rainfall-thunder-flash-background_1017-32181.jpg?size=626&ext=jpg);
  -webkit-filter: brightness(3);
  filter: brightness(3);
  -o-filter: brightness(3);
  -moz-filter: brightness(3);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 15px;
}

/*Now just a opacity animation*/
.flashit {
  -webkit-animation: flash ease-out 7s infinite;
  -moz-animation: flash ease-out 7s infinite;
  animation: flash ease-out 7s infinite;
  animation-delay: 2s;
}

@keyframes raindrop {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}

.sun {
  background: url(https://media.istockphoto.com/id/1322301019/fr/vectoriel/ciel-color%C3%A9-avec-des-nuages-pendant-le-jour-du-soleil.jpg?s=612x612&w=0&k=20&c=uQ1br-GTfhAH_viwPWbta3yPIWM7OQPu4szfQ53cK-0=);
  background-size: cover;
  border-radius: 15px;
  box-shadow: 0 0 19px 3px rgba(0, 0, 0, 0.1);
  margin: 15px;
  background-position: center;
  .body_container {
    align-items: center;
    display: flex;
    justify-content: space-around;
    position: relative;
    .tool_info {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      z-index: 2;
      color: white;
      .outil_name {
        font-size: large;
        font-weight: 700;
        margin-bottom: 5px;
        color: black;
      }
      p {
        color: black;
        font-size: 14px;
      }
    }
    .tool_weather {
      z-index: 2;
      .sun-rays {
        -webkit-animation: rays 10s infinite;
        animation: rays 10s infinite;
        background: radial-gradient(
          rgba(255, 237, 175, 0.8) 50%,
          rgba(255, 237, 175, 0.2) 70%
        );
        border-radius: 50%;
        box-shadow: 0 0 0.5em #ffedaf;
        height: 100px;
        position: absolute;
        width: 100px;
        z-index: -5;
      }
      .sun {
        background: #ffedaf;
        border-radius: 50%;
        box-shadow: 0 0 1em rgba(255, 237, 175, 0.5), inset 0 0 0.5em #ffffad;
        display: block;
        height: 70px;
        width: 70px;
      }

      /*Sun Animation*/
      @-webkit-keyframes rays {
        0% {
          transform: scale(1.1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1.1);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1.1);
        }
      }

      @-moz-keyframes rays {
        0% {
          transform: scale(1.1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1.1);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1.1);
        }
      }

      @keyframes rays {
        0% {
          transform: scale(1.1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1.1);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1.1);
        }
      }
    }
  }
}

.wind {
  background: url(https://img.freepik.com/premium-vector/beautiful-fluffy-clouds-blue-sky-background-clouds-blue-sky-banner-vector-clouds-border-clouds-blue-background-realistic-fluffy-cloud-vector-illustration_561158-1244.jpg?w=2000);
  background-position: 1px -41px;
  background-size: cover;
  border-radius: 15px;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1);
  margin: 15px;
  .body_container {
    align-items: center;
    display: flex;
    justify-content: space-around;
    position: relative;
    .tool_info {
      color: #404040;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      z-index: 2;
      .outil_name {
        font-weight: 700;
        margin-bottom: 5px;
        font-size: large;
      }
      p {
        font-size: 14px;
      }
    }
    .tool_weather {
      z-index: 2;
      .sun-rays {
        -webkit-animation: rays 10s infinite;
        animation: rays 10s infinite;
        background: radial-gradient(
          rgba(255, 237, 175, 0.8) 50%,
          rgba(255, 237, 175, 0.2) 70%
        );
        border-radius: 50%;
        box-shadow: 0 0 0.5em #ffedaf;
        height: 100px;
        position: absolute;
        width: 100px;
        z-index: -5;
      }
      .sun {
        background: #ffedaf;
        border-radius: 50%;
        box-shadow: 0 0 1em rgba(255, 237, 175, 0.5), inset 0 0 0.5em #ffffad;
        display: block;
        height: 70px;
        width: 70px;
      }
      .icon .fa-sun-o {
        font-size: 88pt;
        position: absolute;
        text-shadow: 0 0 20px #fff;
      }
      .icon .fa-cloud {
        color: hsl(0deg 0.1% 90.87% / 70%);
        font-size: 58pt;
        position: absolute;
        right: 83px;
        top: 4px;
      }
      .icon .fa-cloud + .two {
        color: hsla(0, 0%, 81%, 0.39);
        font-size: 43pt;
        right: 77px;
        top: -3px;
      }

      .wind_cloud {
        animation: wind_cloud 14.5s ease-in-out infinite;
      }
      @keyframes wind_cloud {
        50% {
          top: 30px;
          right: 15px;
        }
      }

      /*Sun Animation*/
      @-webkit-keyframes rays {
        0% {
          transform: scale(1.1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1.1);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1.1);
        }
      }

      @-moz-keyframes rays {
        0% {
          transform: scale(1.1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1.1);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1.1);
        }
      }

      @keyframes rays {
        0% {
          transform: scale(1.1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1.1);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1.1);
        }
      }
    }
  }
}

@-webkit-keyframes flash {
  from {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  93% {
    opacity: 0.6;
  }
  94% {
    opacity: 0.2;
  }
  96% {
    opacity: 0.9;
  }
  to {
    opacity: 0;
  }
}

@keyframes flash {
  from {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  93% {
    opacity: 0.6;
  }
  94% {
    opacity: 0.2;
  }
  96% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slick-prev {
  left: 15px;
  z-index: 0;
}

.slick-next {
  right: 21px;
}

element.style {
  display: block;
}
.slick-next {
  right: 16px;
}
.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
  background: #0000001c;
  color: #fffefe;
  outline: none;
  border-radius: 50%;
}

.slick-dots {
  bottom: -5px;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
