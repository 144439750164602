.red {
  color: red;
}

.placement {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  p {
    margin-right: 5px;
  }
  p.usersLiked {
    display: flex;
    align-items: center;
    img {
      border: 1px solid #d3d3d3;
      border-radius: 50%;
      margin-left: -3px;
      width: 15px;
      box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6) !important;
    }
  }
  li.userLikeListe {
    display: flex;
    gap: 1rem;
    img {
      width: 20px;
    }
  }
}

.profil_like_edit {
  visibility: hidden;
  position: fixed;
  padding: 10px 20px;
  width: 552px;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  background: white;
  visibility: hidden;
  opacity: 0;
  top: 15%;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
  }
  .content_like {
    overflow: auto;
    max-height: 383px;
    li.userLikeListe {
      align-items: center;
      display: flex;
      gap: 1.2rem;
      padding: 0.5rem;
      position: relative;
      border-bottom: 1px solid lightgrey;
      margin: 5px 20px 5px 20px;
      p {
        color: #999;
        font-size: 0.8rem;
        text-transform: capitalize;
      }
    }
    img {
      width: 40px;
      border-radius: 50%;
    }
    .heart_like {
      background: lightpink;
      border-radius: 50%;
      color: #e71588;
      left: 35px;
      position: absolute;
      top: 36px;
      border: 2px solid white;
      font-size: 12px;
    }
  }
}
.active_edit_like {
  visibility: visible;
  opacity: 1 !important;
  box-shadow: 0px 0px 100px rgb(0 0 0 / 100%) !important;
  padding: 0 !important;
  z-index: 1;
  h3 {
    border-radius: 15px 15px 0 0;
    color: black;
    margin: 0;
    padding: 1rem;
    border-bottom: 1px solid lightgray;
    margin-left: 5px;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: -1;
    border-radius: 15px;
  }
  &::after {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: -2;
  }
}
