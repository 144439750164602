.red {
  color: #323c3a;
}

.placement {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  p.usersLiked {
    display: flex;
    align-items: center;
    img {
      width: 15px;
      border: 1px solid lightgrey;
      border-radius: 50%;
      margin-left: -5px;
    }
  }
  li.userLikeListe {
    display: flex;
    gap: 1rem;
    img {
      width: 20px;
    }
  }
}

.profil_notif_edit {
  visibility: hidden;
  position: absolute;
  top: 51px;
  right: 173px;
  padding: 10px 20px;
  width: 100%;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  background: white;
  visibility: hidden;
  opacity: 0;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  li.userLikeListe {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.2rem;
  }
  img {
    width: 25px;
  }
}
.active_edit_notif {
  visibility: visible;
  opacity: 1 !important;
  box-shadow: 0px 0px 100px rgb(0 0 0 / 100%) !important;
  padding: 2rem;
  z-index: 1;
  width: 400px;
  .notif {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 8px;
    gap: 1rem;
    border-bottom: 1px solid lightgrey;
    background: ghostwhite;
    i.far.fa-eye {
      color: #4e5166;
    }
    .notif_like {
      display: flex;
      gap: 18px;
      align-items: center;
      position: relative;
      a {
        display: flex;
        gap: 24px;
      }
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .notif_like_info {
        p {
          font-weight: 800;
        }
        span {
          color: #010101;
          font-weight: 300;
        }
      }
      p.icon {
        position: absolute;
        background: white;
        border-radius: 50%;
        bottom: 0;
        border: 1px solid lightgray;
        left: 28px;
      }
    }
  }
}

.notificaion {
  position: relative;
  i.far.fa-bell {
    font-size: 18px;
  }
  span.badge {
    background: red;
    width: 15px;
    height: 15px;
    padding: 2px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    font-size: 10px;
    right: -15px;
  }
}

@media screen and (max-width: 768px) {
  .profil_notif_edit {
    right: 5px;
    width: 300px;
  }
}
