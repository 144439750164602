.knowledgerInfo-card {
  background: transparent;
  padding: 1rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;
  .knwoledge-menu {
    .new-knowledge {
      display: flex;
      justify-content: space-between;
    }
  }
}
.texte {
  position: absolute;
  left: 40%;
}
.back-knowlegde {
  background-color: #4e5166;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  line-height: 1.5;
  padding: 0.5rem;
  text-align: center;
  width: 293px;
}
.favorite-info-button {
  background-color: #4e5166;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.05rem;
  line-height: 1.5;
  padding: 0.5rem;
  width: 170px;
}
.knowledge-container-more {
  background: white;
  padding: 1.6rem;
  border-radius: 15px;
  box-shadow: 0px 15px 32px rgb(0 0 0 / 16%) !important;
  h3 {
    margin-bottom: 1.5rem;
  }
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    background: #8d88880a;
    width: 97%;
    border: 1px solid lightgray;
  }
  button.btn {
    border: 1px solid transparent;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    padding: 0.5rem;
    text-align: center;
    background: #4e5166;
  }
  input[type="text"] {
    width: 97%;
    background: rgba(140, 135, 135, 0.04);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .cardfooter-comment {
    align-items: center;
    background: #fff;
    border-radius: 15px;
    box-shadow: 1px 1px 15px 1px hsla(0, 0%, 83%, 0.38);
    color: #000;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    gap: 0.6rem;
    margin-bottom: 0.3rem;
    margin-top: 0.4rem;
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
}
