.add-new-knowledge {
  visibility: hidden;
  position: absolute;
  top: 0px;
  right: 25%;
  padding: 10px 20px;
  width: 700px;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  background: white;
  visibility: hidden;
  opacity: 0;
}
.active_knowledge {
  visibility: visible !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 100px rgb(0 0 0 / 100%) !important;
  padding: 2rem;
  z-index: 1;
}
.texte-knowledge {
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #555770;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  .ck.ck-editor__editable_inline[dir="ltr"] {
    text-align: left;
    width: 97%;
  }
  .ck-widget.raw-html-embed .raw-html-embed__buttons-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
  }
  li {
    list-style: inherit !important;
    margin-left: 1.5rem;
  }
  .todo-list {
    .todo-list__label > input {
      -webkit-appearance: auto !important;
      border: 0;
      display: inline-block;
      height: var(--ck-todo-list-checkmark-size);
      left: -25px;
      margin-left: 0;
      margin-right: -15px;
      position: relative;
      right: 0;
      vertical-align: middle;
      width: var(--ck-todo-list-checkmark-size);
    }
  }
}
