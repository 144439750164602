form.quizzForm {
  display: flex;
  gap: 3rem;
  .topForm {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  .middlemiddle {
    display: flex;
    flex-direction: row;
    .middleForm {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      position: relative;
      flex-wrap: wrap;
      .questionInput {
        background: whitesmoke;
        border: 1px solid #ced4da;
        border-radius: 5px;
        color: #555770;
        display: block;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 0.375rem 0.75rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
      button {
        background: #fff6e0;
        border: 1px solid transparent;
        border-radius: 0.5rem;
        color: #ffb911;
        cursor: pointer;
        margin-top: 20px;
        padding: 0.6rem;
        width: 150px;
      }
    }
  }
}

.delete-question {
  background: #ff00007a !important;
  padding: 5px !important;
  width: 100% !important;
  color: white !important;
}
.middleResponse {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 5px;
  background: whitesmoke;
  border-radius: 5px;
  label {
    display: flex;
    justify-content: space-between;
    padding: 2px;
  }
}
