.tools-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid lightgrey;
  gap: 15px;
  margin-top: 20px;
  padding: 1rem;
  .filtre {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .titre {
    font-size: 2rem;
    display: inline-block;
    color: darkslategrey;
    flex: 1;
  }
}

.img-banner {
  background: url(../../asset/coffre.png) !important;
  height: 145px;
  background-position: center !important;
  background-size: cover !important;
  cursor: pointer;
}

.toolsMainContainer_outils {
  background: #494b5f;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 5px;
  .tools_img {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    padding: 1rem;
    width: 33%;
    flex-direction: column;
    gap: 1rem;
    width: 29.4%;
    position: relative;
    .tools_info {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: bold;
      font-size: 14px;
      .tools_name {
        font-size: 17px;
        img {
          width: 35px;
        }
      }
      .icone {
        font-size: 25px;
        color: #f7a600;
      }
    }
    .tools-desc p {
      line-height: 20px;
      margin-top: 8px;
      color: rgba(17, 17, 17, 0.7);
      font-size: 15px;
    }
    .hover-overlay {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.toolsMainContainer {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
  margin-top: 1rem;
  overflow: hidden;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  flex-wrap: wrap;
  .tools {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
    overflow: hidden;
    padding: 1rem;
    width: 33%;
    cursor: pointer;
    .converter {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .steptwo {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .upload_csv {
          border: 1px solid transparent;
          border-radius: 0.5rem;
          color: #fff;
          cursor: pointer;
          padding: 0.5rem;
          text-align: center;
          background: #4e5166;
        }
        form .submit_prompt {
          background: #4e5166;
          border: 1px solid transparent;
          border-radius: 0.5rem;
          color: #fff;
          cursor: pointer;
          padding: 0.5rem;
          text-align: center;
        }
      }
      .stepthree {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        button {
          background: #71ab72;
          border: 1px solid transparent;
          border-radius: 0.5rem;
          color: #fff;
          cursor: pointer;
          padding: 0.5rem;
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  .tools_img {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
    min-height: 95px;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    padding: 1rem;
    width: 33%;
    flex-direction: column;
    gap: 1rem;
    width: 29.4%;
    .tools_info {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: bold;
      font-size: 14px;
      .tools_name {
        font-size: 17px;
        img {
          width: 35px;
        }
      }
      .icone {
        font-size: 25px;
        color: #f7a600;
      }
    }
    .tools-desc p {
      line-height: 20px;
      margin-top: 8px;
      color: rgba(17, 17, 17, 0.7);
      font-size: 15px;
    }
  }
}

.tools_all {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 15px 32px rgba(0, 0, 0, 0.16) !important;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  .tools_info {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: bold;
    font-size: 14px;
    .tools_name {
      font-size: 17px;
      img {
        width: 35px;
      }
    }
    .icone {
      font-size: 25px;
      color: #f7a600;
    }
  }
  .tools-desc p {
    line-height: 20px;
    margin-top: 8px;
    color: rgba(17, 17, 17, 0.7);
    font-size: 15px;
  }
}
