.card-knowledge-container {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.16) !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  width: 30%;
  .knowledge-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
    span.contentKnowledge {
      border-bottom: 1px solid rgba(58, 53, 65, 0.12);
      min-height: 330px;
    }
    span.title {
      color: #000;
      display: flex;
      font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Helvetica Neue, Arial, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol;
      font-size: 18px;
      font-weight: 700;
      gap: 1rem;
      letter-spacing: 0.15px;
      line-height: 1.5;
      padding: 0.5rem 1.5rem 1rem 1.5rem;
      .knowledge_info {
        align-items: center;
        background: #fce0a6;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        text-align: center;
        width: 45px;
        max-height: 39px;
        justify-content: center;

        .date {
          color: #bf8718;
          font-size: 0.8rem;
          font-style: italic;
          text-transform: capitalize;
        }
        .carFooter {
          background: hsla(0, 0%, 83%, 0.09);
          border-radius: 5px;
          border-top: 1px solid #d3d3d3;
          color: #000;
          display: flex;
          padding: 1rem;
          justify-content: space-around;
        }
      }
      .knowledge_title {
        display: flex;
        flex-direction: column;
        p {
          margin: 0px;
          letter-spacing: 0.15px;
          font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 1rem;
          line-height: 1.5;
          color: rgba(58, 53, 65, 0.87);
          font-weight: 600;
        }
        span {
          margin: 0px;
          font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.66;
          color: rgba(58, 53, 65, 0.6);
          letter-spacing: 0.4px;
        }
        .user {
          color: #999;
          font-size: 0.8rem;
          text-transform: capitalize;
          font-style: italic;
        }
      }
    }
    span.categories {
      background-color: #4e5166 !important;
      border-radius: 15px;
      color: #ffffff;
      font-size: 12px;
      left: 10px;
      padding: 5px 10px 5px 10px;
      position: absolute;
      text-align: center;
      top: 10px;
    }

    .cardheader-container-favorite {
      position: absolute;
      top: -2px;
      right: 10px;
      cursor: pointer;
      .favorite-button {
        border: none;
        background: transparent;
        font-size: 20px;
        color: #4e5166;
      }
    }
    .cardheader-container-delete {
      color: rgb(255, 76, 81);
      background-color: rgba(255, 76, 81, 0.12);
      left: 110px;
      position: absolute;
      top: 11px;
      padding: 5px;
      border-radius: 5px;
      font-size: 11px;
      cursor: pointer;
    }
  }
}
