@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

:root {
  $primaryColor: #fd2d01;
  $secondaryColor: #ffd7d7;
  $tertiaireColor: #4e5166;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: 0;
}

body {
  background: #e7e3e3;
  font-family: Lato, sans-serif;
}

li {
  list-style-type: none;
}

a {
  all: unset;
  cursor: pointer;
}

select {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #555770;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

td {
  padding: 5px 5px 10px 10px;
  text-align: left;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.66;
  color: lightslategray;
  a {
    display: flex;
    justify-content: center;
    // button {
    //   // width: 60%;
    // }
  }
}

tr {
  text-align: left;
}

table {
  margin-top: 2rem;
  width: 100%;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

th {
  background: #f4f5fa;
  border-radius: 5px;
  color: rgba(58, 53, 65, 0.87);
  padding: 10px 10px 10px 15px;
}

.blackButton {
  background: #4e5166;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem;
  text-align: center;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}
.blockedButton {
  background: rgb(78 81 102 / 26%);
  border: 1px solid transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: help;
  padding: 0.5rem;
  text-align: center;
}

.greenButton {
  background: rgb(31 177 65 / 64%);
  border: 1px solid transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem;
  text-align: center;
}

.blackButtonTable {
  background: #4e5166;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem;
  text-align: center;
  width: 60%;
}

.deleteButton {
  background-color: rgba(255, 76, 81, 0.12);
  border-radius: 5px;
  color: #ff4c51;
  font-size: 14px;
  padding: 8px;
  border: none;
}
.flex {
  display: flex;
  align-items: center;
  gap: 1rem;
  span {
    font-weight: 600;
    font-size: 13px;
    line-height: 1.66;
  }
}

.flexTable {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
  min-width: 155px;
}

.flexSolo {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.toolNameCell {
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
    width: 35px;
    border-radius: 50%;
  }
}

.pagination {
  display: flex;
  align-items: center;
  background: #f4f5fa;
  border-radius: 5px;
  color: #524e59;
  gap: 2rem;
  justify-content: space-around;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 5px;
  width: 100%;
  button {
    padding: 4px;
    width: 100px;
    border-radius: 5px;
    border: none;
    background: white;
    cursor: pointer;
  }
}

.select-formation {
  display: flex;
  gap: 1rem;
  padding: 1rem;
}
.graphique {
  display: flex;
  padding: 2rem;
  align-items: center;
}

.blue-mention {
  color: #525fff;
  font-size: 15px;
  background: #add8e63b;
  padding: 4px;
  border-radius: 8px;
  font-weight: 600;
}

.custom-comment-form {
  color: rgb(85, 87, 112);
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(206, 212, 218);
  border-image: initial;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.custom-comment-form__input {
  background: white !important;
}

.telecharger {
  padding: 1rem;
  background: rgb(255 246 224);
  color: #f6ad16;
  width: 150px;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 30px;
  right: 40px;
}

.react-tooltip.styles-module_tooltip__mnnfp {
  z-index: 2;
}

.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #2f313d;
  color: #fff;
  font-size: 1em;
}

/* CSS pour le modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 600px;
  position: relative;
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    border: 1px solid lightgrey;
    border-radius: 5px;
  }
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content form div {
  margin-bottom: 10px;
}

.modal-content label {
  display: block;
  margin-bottom: 5px;
  margin-top: 20px;
}

.modal-content input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content .modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 2rem;
  button {
    background: #fff6e0;
    border: none;
    border-radius: 5px;
    color: #ffb911;
    font-weight: 600;
    padding: 0.7rem;
    width: 150px;
    cursor: pointer;
  }
}

.modal-content .close {
  background: none !important;
  border: none !important;
  cursor: pointer;
  margin-left: 10px;
  padding: 0px !important;
  position: absolute;
  right: -60px;
  top: 7px;
  color: black !important;
}

.bs-stepper-header {
  align-items: center;
  display: flex;
  margin-top: 2rem;
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
  background-color: rgb(255 246 224);
  border-radius: 5px;
  color: #ffb911;
  cursor: pointer;
}

.bs-stepper .step-trigger.disabled,
.bs-stepper .step-trigger:disabled {
  opacity: 0.65;
  pointer-events: none;
  background: #d3d3d34f;
}

.formationTab {
  min-height: 600px !important;
}

.bs-stepper-action {
  display: flex;
  gap: 10rem;
  justify-content: flex-end;
  button {
    background: #fff6e0;
    border: none;
    padding: 0.7rem;
    width: 150px;
    border-radius: 5px;
    color: #ffb911;
    font-weight: 600;
  }
}

.formStyle {
  color: #5cabff;
  font-weight: 700;
  padding: 1rem;
}

.input_range {
  margin: 10px 0 50px 0 !important;
}

.texteFinish {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 2rem;
  .finishText {
    padding: 1rem;
  }
  .finishImg {
    img {
      width: 200px !important;
    }
  }
  .fleche {
    bottom: -35px;
    position: absolute;
    right: 15px;
    width: 100px;
  }
}

.textePoucents {
  margin-bottom: 2rem;
  text-align: center;
}

.emoji {
  position: relative;
  min-height: 50px;
  .emojiSad {
    position: absolute;
    bottom: -35px;
    left: -19px;
    width: 50px;
  }
  .emojiMid {
    position: absolute;
    bottom: -35px;
    left: 48%;
    right: 50%;
    width: 50px;
  }
  .emojiTop {
    position: absolute;
    right: -37px;
    width: 50px;
    bottom: -35px;
  }
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: flex-end;
  width: 100%;
}

.textTutoRequest {
  padding: 2rem;
}

.validationKnowledge {
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.close-button {
  position: absolute;
  right: 15px;
  top: 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.coverTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  padding: 1rem;
  min-height: 122px;
}

.full-width {
  width: auto !important;
}

.full-width-modal {
  width: 60%;
}

.search-icon-container {
  color: #bfbdbd;
  position: absolute;
  right: 23px;
  top: 7px;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.success-span {
  color: #2ecc71;
}

.error-span {
  color: red;
}

.icone {
  width: 30px;
}

.tetris-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 2px solid #000;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Assurez-vous que la popup soit au-dessus du reste du contenu */
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.close-buttons {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
}

.close-buttons:hover {
  background-color: #dd0000;
}
