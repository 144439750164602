.centerBlockDashboard {
  background: transparent;
  border-radius: 5px;
  margin: 1rem 1rem 1rem 20%;
  padding: 1.6rem;
  position: relative;
  width: 100%;
  .adminWidget {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    .smallWidget {
      background: #fff;
      border-radius: 5px;
      padding: 1rem;
      width: 20.8%;
      min-height: 115px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
      position: relative;
      margin-top: 3rem;
      p {
        color: rgba(58, 53, 65, 0.87);
        font-size: 1.125rem;
        font-weight: 700;
        letter-spacing: 0.15px;
        line-height: 1.6;
        span {
          font-size: 2rem;
          margin-left: 1rem;
        }
      }
      .image {
        bottom: -12px;
        position: absolute;
        right: -45px;
        img {
          width: 210px;
        }
      }
    }
    .bigWidget {
      background: #fff;
      border-radius: 5px;
      padding: 1rem;
      width: 46%;
      min-height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
      justify-content: space-around;
      position: relative;
      p {
        color: rgba(58, 53, 65, 0.87);
        font-size: 1.125rem;
        font-weight: 700;
        letter-spacing: 0.15px;
        line-height: 1.6;
      }
      .sousTexte {
        font-weight: 600;
        color: rgba(58, 53, 65, 0.87);
        line-height: 1.5;
        letter-spacing: 0.15px;
        font-weight: 600;
        font-size: 0.875rem;
        span {
          font-weight: 400;
          font-size: 0.875rem;
          color: rgba(58, 53, 65, 0.6);
        }
      }
      .extension {
        display: flex;
        gap: 6rem;
        align-items: center;
        .extensionPart {
          display: flex;
          gap: 0.7rem;
          align-items: center;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            background: rgba(255, 180, 0, 0.12);
            color: #f8ad13;
            width: 40px;
            height: 40px;
          }
          .monthStats {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            p {
              letter-spacing: 0.4px;
              font-weight: 400;
              font-size: 0.75rem;
              line-height: 1.66;
              color: rgba(58, 53, 65, 0.6);
            }
            span {
              letter-spacing: 0.15px;
              font-weight: 700;
              font-size: 1.125rem;
              line-height: 1.6;
              color: rgba(58, 53, 65, 0.87);
            }
          }
        }
        .chart {
          width: 100%;
        }
      }
    }
  }
  line {
    display: none;
  }
}
