/* Ajoutez ces styles CSS pour le modal du quiz */
.quiz-modal {
  background-color: #474963;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  left: 50%;
  color: white;
  padding: 20px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 850px;
  border-radius: 15px;
  z-index: 10;
  max-height: 80vh;
  overflow-y: auto;
}
.quiz-summary {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  align-items: center;
  p {
    font-size: 18px;
    font-weight: 700;
    background: #66647d;
    padding: 1rem;
    border-radius: 10px;
  }
}
.finish {
  background: #66647d;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  padding: 0.3rem;
  color: white;
  width: 300px;
  z-index: 1111;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.scoreUser {
  background: #66647d;
  border-radius: 10px;
  font-weight: 700;
  padding: 1rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .url_site {
    background: #3d3a4e;
    background-size: 400%;
    border: none;
    border-radius: 1.5rem;
    color: #fff;
    height: 2.5rem;
    overflow: hidden;
    padding: 0 2rem;
    position: relative;
    margin-top: 1rem;
    cursor: pointer;
  }
  .url_site:hover::before {
    transform: scaleX(1);
  }
  .button-content {
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  .url_site::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(
      82.3deg,
      rgba(150, 93, 233, 1) 10.8%,
      rgba(99, 88, 238, 1) 94.3%
    );
    transition: all 0.475s;
  }
}

.tutorielQuiz {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.bottomText {
  width: 30%;
  border: 1px solid #66647d;
  border-radius: 10px;
}

.podium {
  background: #66647d;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
  p {
    text-align: center;
    font-size: 18px;
  }
  ul {
    display: flex;
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 45%;
      .user {
        display: flex;
        gap: 1rem;
        background: #474963;
        padding: 1rem;
        border-radius: 10px;
        align-items: center;
        span {
          background: #66647d;
          border-radius: 50%;
          padding: 0.2rem;
        }
      }
      img {
        width: 30px;
        border-radius: 50%;
      }
    }
  }
}
.quiz-presentation h3 {
  text-align: center;
  padding: 1rem;
  background: #474963;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.quiz-presentation button {
  display: block;
  margin: 0 auto;
}

.quiz-question {
  position: relative;
  padding: 1rem;
  .timer {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 1rem;
    background: #66647d;
    border-radius: 10px;
    .timerClock {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 30px !important;
        margin-right: 10px;
      }
    }
  }
  .url_site {
    background: #3d3a4e;
    background-size: 400%;
    border: none;
    border-radius: 1.5rem;
    color: #fff;
    height: 2.5rem;
    overflow: hidden;
    padding: 0 2rem;
    position: relative;
    margin-top: 1rem;
    cursor: pointer;
  }
  .url_site:hover::before {
    transform: scaleX(1);
  }
  .button-content {
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  .url_site::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(
      82.3deg,
      rgba(150, 93, 233, 1) 10.8%,
      rgba(99, 88, 238, 1) 94.3%
    );
    transition: all 0.475s;
  }
}

.questionQuiz {
  text-align: center;
  padding: 1rem;
  background: #66647d;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.response {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    li {
      padding: 1rem;
      background: #66647d;
      border-radius: 10px;
      width: 45%;
      input {
        margin-right: 10px;
      }
    }
    li:hover {
      background: #434153;
    }
  }
}

.quiz-question ul {
  list-style: none;
  padding: 0;
}

.quiz-question ul li {
  margin-bottom: 10px;
}

.quiz-question button {
  display: block;
  margin: 0 auto;
}

.red-timer {
  color: red;
}

.fire {
  position: absolute;
  bottom: 0;
  right: 0;
}

.thumbs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.quizQuestion {
  margin-top: 4rem;
}

.answer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0 !important;
  p {
    padding: 0.3rem;
    font-size: 14px;
  }
}

.quizzAdminList {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  .rightPart {
    display: flex;
    gap: 1rem;
    button {
      padding: 0.5rem;
      background: #434e48;
      border: none;
      border-radius: 5px;
      color: white;
    }
    .deleteButton {
      background: red;
    }
  }
}

.selectedAnswer {
  background: #3d3a4e !important;
  border: 1px solid green;
}
