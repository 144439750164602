.formation_create {
  border-radius: 10px;
  box-shadow: 0px 0px 20px 7px rgb(0 0 0 / 7%);
  padding: 20px;
  margin-bottom: 1rem;
  .title_form {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .formation_aquise {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .formation_container {
      width: 22%;
      position: relative;
      padding: 0.9rem;
      .formation_body {
        p {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
      .formation_lvl {
        p {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 10px;
          color: rgba(255, 0, 0, 0.384);
        }
      }
      .progress_formation {
        p {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 0px;
          margin-top: 20px;
        }
        progress.progress.is-danger {
          width: 100%;
        }
        i.far.fa-trash-alt.poub {
          position: absolute;
          top: 10px;
          right: 10px;
          color: red;
        }
      }
      .formation_container_footer_admin {
        display: flex;
        justify-content: space-between;
        span {
          font-size: 12px;
          font-weight: 700;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .formationDescription {
    border: 1px solid lightgrey;
    border-radius: 5px;
    .ck.ck-editor__editable_inline[dir="ltr"] {
      text-align: left;
      width: 98%;
    }
    .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
      background: white;
      border-radius: 13px;
      width: 97%;
    }
    p {
      font-size: inherit;
      font-weight: inherit;
      margin-bottom: inherit;
    }
    .ck-content .todo-list .todo-list__label > input {
      -webkit-appearance: inner-spin-button;
      border: 0;
      display: inline-block;
      height: var(--ck-todo-list-checkmark-size);
      left: -25px;
      margin-left: 0;
      margin-right: -15px;
      position: relative;
      right: 0;
      vertical-align: middle;
      width: var(--ck-todo-list-checkmark-size);
    }
    li {
      list-style-type: inherit;
      margin-left: 1.5rem;
    }
  }
  h3 {
    margin-bottom: 1rem;
  }
}
.formation {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  .formation_container {
    border-radius: 10px;
    box-shadow: 0px 0px 20px 7px rgb(0 0 0 / 7%);
    position: relative;
    .formation_body {
      padding: 15px;
      p {
        font-size: 18px;
        font-weight: 700;
        margin-top: 10px;
      }
    }
    .formation_lvl {
      display: flex;
      padding: 10px 15px 0 15px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      i.far.fa-clock {
        color: black;
      }
      .react-tooltip p span {
        color: white !important;
      }
    }
    .progress_formation {
      padding: 3px 15px 3px 15px;
      progress.progress.is-danger {
        width: 100%;
      }
    }
    i.far.fa-trash-alt.poub {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 13px;
      color: red;
    }
    i.far.fa-edit {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 13px;
      color: blue;
    }
  }
}

.searchFormation {
  border-radius: 5px;
  box-shadow: 0 0px 22px rgba(0, 0, 0, 0.16) !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
  padding: 1rem;
  position: relative;
  align-items: center;
  background: white;
  input[type="text"] {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 40px;
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    outline: none;
    padding: 9px;
    transition: all 0.3s ease;
    width: 97%;
  }
  .search-icon-container {
    color: #bfbdbd;
    position: absolute;
    right: 40px;
    top: 24px;
  }
}

.formationDescription {
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    border: 1px solid lightgrey;
    border-radius: 5px;
  }
}
