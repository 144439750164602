.addOutil {
  border-radius: 10px;
  box-shadow: 0 0 20px 7px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  margin: 40px 1rem 1rem;
  padding: 20px;
  form {
    align-items: center;
    display: flex;
    margin-top: -0.5rem;
    width: 100%;
    .new_outil {
      display: flex;
      gap: 1rem;
      padding: 1rem;
      flex-direction: column;
      width: 100%;
      label {
        font-weight: 700;
      }
    }
    button {
      background: #fff6e0;
      border: 1px solid transparent;
      border-radius: 0.5rem;
      color: #ffb911;
      cursor: pointer;
      width: 150px;
      padding: 0.6rem;
    }
  }
}

.allOutils {
  align-items: flex-start;
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  gap: 5px;
  .outils {
    align-items: flex-start;
    border: 1px solid #d3d3d3;
    border-radius: 15px;
    display: flex;
    gap: 0.5rem;
    padding: 1rem;
    width: 370px;
    flex-direction: column;
    .leftPart {
      flex: 0.2;
      span {
        font-weight: 700;
      }
    }
    .rightPart {
      display: flex;
      gap: 1rem;
      button {
        background: #4e5166;
        border: 1px solid transparent;
        border-radius: 0.5rem;
        color: #fff;
        cursor: pointer;
        width: 100px;
      }
    }
    .bottomPart {
      overflow: hidden;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
      }
      .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
        background: #fff;
        border-radius: 13px;
        width: 94%;
        border: 1px solid lightgray;
      }
    }
  }
}

.allOutilsExt {
  align-items: flex-start;
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
  .outils {
    align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    font-size: 25px;
    font-weight: 600;
    gap: 1rem;
    padding: 1rem;
    width: 370px;
    justify-content: center;
    background: white;
    .bottomPart {
      width: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 15px;
      align-items: center;
      overflow: hidden;
      p {
        display: flex;
        gap: 1rem;
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 0.3rem;
        background: linear-gradient(170deg, #202243, #282c52bd);
        color: white;
        font-weight: 500;
        width: 100%;
        span {
          font-weight: 300;
          flex: 1;
          i.fa-solid.fa-copy {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      i {
        color: #e7a328;
      }
      .url_site {
        background: #3d3a4e;
        background-size: 400%;
        border: none;
        border-radius: 1.5rem;
        color: #fff;
        height: 2.5rem;
        overflow: hidden;
        padding: 0 2rem;
        position: relative;
        margin-top: 1rem;
        cursor: pointer;
      }
      .url_site:hover::before {
        transform: scaleX(1);
      }
      .button-content {
        position: relative;
        z-index: 1;
      }
      .url_site::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        width: 100%;
        height: inherit;
        border-radius: inherit;
        background: linear-gradient(
          82.3deg,
          rgba(150, 93, 233, 1) 10.8%,
          rgba(99, 88, 238, 1) 94.3%
        );
        transition: all 0.475s;
      }
    }
  }
}

.information {
  background-image: url(../../asset/coffre-bg.png);
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10rem;
  justify-content: center;
  min-height: calc(70vh - 60px);
  padding-top: 2rem;
  .fa-times-circle {
    color: white;
  }
}

.col-left {
  display: flex;
  flex-direction: column;
  .main-title {
    display: block;
    margin: 2rem 0;
    font-size: 42px;
    font-family: "8bit Plus Bold", sans-serif;
    color: white;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    min-height: 430px;
    margin: 0 0 4rem;
    padding: 15px;
    border: 1px solid #639dcd;
    color: white;
    font-family: "8bit Plus Regular", sans-serif;
    font-size: 14px;
    line-height: 1.4;
    .text-header {
      background-color: rgba(99, 157, 205, 0.2);
      padding: 7px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }
    .text-body {
      flex: 1;
      padding: 10px;
      li {
        padding: 1rem;
      }
    }
  }
}

.col-right {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  .coffre {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    height: 600px;
    width: 600px;
    margin: 0 auto 2rem;
    background-image: url(./../../asset/coffre-crack.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    .formPass {
      display: block;
      width: 184px;
      .lock-password {
        display: block;
        margin-bottom: 1rem;
        color: #fff;
        font-size: 32px;
        font-family: "Open Sans ExtraBold", sans-serif;
        text-align: center;
        outline: none;
        box-sizing: border-box;
        width: 100%;
        border: 2px solid #484974;
        border-radius: 15px;
        background-color: #212344;
      }
      .check-pass {
        font-weight: 600;
        font-family: "Open Sans ExtraBold", sans-serif;
        background-color: #169383;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        color: white;
        padding: 10px;
      }
      form {
        p {
          padding: 1rem;
          text-align: center;
        }
        .error-message {
          color: red;
        }
        .success-message {
          color: green;
        }
      }
    }
  }
}

.video-container {
  display: flex;
  justify-content: center;
  video {
    width: 80%;
  }
}

.outil_content {
  display: flex;
  align-items: center;
  i {
    font-size: 15px;
    color: #7d7d85;
  }
}
.outil_name {
  margin-left: 10px;
  font-weight: 500;
  color: darkgray;
  font-size: 20px;
}

.searchBar {
  display: flex;
  justify-content: center;
  input[type="text"] {
    appearance: none;
    border: none;
    border-radius: 40px;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.25);
    outline: none;
    padding: 9px;
    transition: all 0.3s ease;
    width: 50%;
    margin-bottom: 2rem;
  }
}

.animate-from-left-to-right {
  animation: slide-in 1.5s forwards;
  width: 100%;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
