.requestknowledge {
  align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
  cursor: zoom-in;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin: 0.5rem;
  padding: 1rem;
  .resquestknowledge_body {
    p {
      font-weight: 700;
    }
    span {
      color: #f7aa0e;
      font-size: 15px;
      font-weight: 700;
    }
  }
  .requestknowledge_cat {
    display: flex;
    gap: 0.5rem;
    p {
      font-weight: 700;
    }
    .formaName {
      color: #f7aa0e;
      font-size: 15px;
      font-weight: 700;
    }
  }
  .requestknowledge_date {
    p {
      font-weight: 700;
    }
    .formaName {
      color: #f7aa0e;
      font-size: 15px;
      font-weight: 700;
    }
  }
}

.resquestknowledge_content {
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin: 0.5rem;
  padding: 1rem;
}
