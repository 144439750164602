@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap");

.loadingBackground {
  background: #f4f4f4;
  height: 100vh;
  .loading_content {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 80px;
      .skills {
        font-family: "Vujahday Script", cursive;
        color: #f7a600;
      }
      .manager {
        font-family: "Mulish", sans-serif;
        color: black;
      }
    }
    .loading-bar {
      width: 200px;
      height: 2px;
      background-color: #d6cec2;
      border-radius: 10px;
      margin-top: 25px;
      overflow: hidden;
      position: relative;
    }
    div.loading-bar::after {
      content: "";
      width: 100px;
      height: 2px;
      position: absolute;
      background-color: #f7a600;
      transform: translateX(-20px);
      animation: loop 2s ease infinite;
    }
    @keyframes loop {
      0%,
      100% {
        transform: translateX(-28px);
      }
      50% {
        transform: translateX(78px);
      }
    }
  }
}
